<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Dodaj novo vozilo
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form id="new-transport-vehicle-form">
                            <div class="mb-3 form-floating" v-if="transportVehicle">
                                <input type="text" class="form-control" id="tId" disabled v-model="transportVehicle.id" placeholder="Id naloga">
                                <label for="tId">Id prevoza</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-name" v-model="transportVehicleForm.name" placeholder="Naziv" required>
                                <label for="field-name">Naziv</label>
                                <div class="invalid-feedback">
                                    Naziv je obavezno polje.
                                </div>
                            </div>
                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-plate-code" v-model="transportVehicleForm.plate_code" placeholder="Tablice vozila">
                                <label for="field-plate-code">Tablice vozila</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="number" min="0" class="form-control" id="field-capacit" v-model="transportVehicleForm.capacity" placeholder="Kapacitet">
                                <label for="field-capacit">Kapacietet </label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="date" class="form-control" id="field-registration-end" v-model="transportVehicleForm.registration_end" placeholder="Registrovan do">
                                <label for="field-registration-end">Registrovan do</label>
                            </div>



                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-make" v-model="transportVehicleForm.make" placeholder="Proizvođač">
                                <label for="field-make">Proizvođač</label>
                            </div>


                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-model" v-model="transportVehicleForm.model" placeholder="Model">
                                <label for="field-model">Model</label>
                            </div>


                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-year" v-model="transportVehicleForm.year" placeholder="Godina proizvodnje">
                                <label for="field-year">Godina proizvodnje</label>
                            </div>


                            <div class="d-flex">
                                <div class="mb-3 form-floating w-100">
                                    <input type="text" class="form-control" id="field-color" v-model="transportVehicleForm.color" placeholder="Boja">
                                    <label for="field-color">Boja</label>
                                </div>
                                <div>
                                    <div class="mb-3 form-floating color-picker-form">
                                        <input type="color" class="form-control" id="field-color-hex" v-model="transportVehicleForm.color_hex" placeholder="Boja">
                                    </div>
                                </div>
                            </div>


                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-descriptoin" v-model="transportVehicleForm.description" placeholder="Opis">
                                <label for="field-descriptoin">Opis</label>
                            </div>
                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createWorkOrder" >{{transportVehicle?'Izmeni':'Dodaj'}}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import TransportService from '@/service/TransportService.js'

export default {

    props: {
        inputId: {
            type: String,
            default: "",
        },

        transportVehicle: {
            type: Object,
            default: null,
        }
    },

    data: function() {
        return {
            transportVehicleForm: {}
        }
    },


    watch:{
        transportVehicle(){
            this.updateProp();
        }
    },

    methods: {
        createWorkOrder() {
            let form = document.getElementById("new-transport-vehicle-form");
            
            form.classList.add('was-validated');
            if (!form.checkValidity())
                return;

            if (this.transportVehicle) {
                this.updateTransportVehicle();
            }
            else {
                this.$store.dispatch('createTransportVehicles', this.transportVehicleForm)
                .then(() => {
                    this.$store.dispatch('loadAllTransportVehicles');
                })
                .catch((error) => alert(error.message));   
                this.$emit('confirm');
 
            }

        },

        async updateTransportVehicle() {
            await TransportService.updateTransportVehicle(this.transportVehicleForm)
            .then(() => {
                this.toast("Uspešna izmena.");
                this.$emit('confirm');
            })
            .catch((error) => alert(error.message))
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        },

        updateProp() {

            if (this.transportVehicle) {
                this.transportVehicleForm.id = this.transportVehicle.id;
                this.transportVehicleForm.name = this.transportVehicle.name;
                this.transportVehicleForm.plate_code = this.transportVehicle.plate_code;
                this.transportVehicleForm.capacity = this.transportVehicle.capacity;
                this.transportVehicleForm.registration_end = this.transportVehicle.registration_end;

                this.transportVehicleForm.make = this.transportVehicle.make;
                this.transportVehicleForm.model = this.transportVehicle.model;
                this.transportVehicleForm.year = this.transportVehicle.year;
                this.transportVehicleForm.color = this.transportVehicle.color;
                this.transportVehicleForm.color_hex = this.transportVehicle.color_hex;

                this.transportVehicleForm.description = this.transportVehicle.description;
            }
        }


    },

    created() {
        this.updateProp()
    },

}
</script>

<style scoped>

    .color-picker-form {
        width: 70px;
        height: 100%;
    }
    .color-picker-form > input{
        padding: 0.625rem !important;
    }

</style>